/*
 * @Date: 2022-03-14 10:09:44
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-15 14:56:05
 * @Description: file content
 * @FilePath: \user-center-frontend\src\apis\api-request.js
 */
import BaseAPIRequest from 'core/lib/store/api-request';
import { getWpsAppid } from '@/utils/wps';

import { getParameterByName, appTokenToPlatform } from '../utils/qs';
import { getUserToken, getCrmContext } from '../utils/platform';
import { handleRequestError, removeInputSpaces } from './interceptor';
import { isQIDIAN } from '@/utils/common';
const jumpToQiDianPage = () => {
  window.location.href = `https://api.qidian.qq.com/cgi-bin/oauth2/authorize?`+
  `appid=202363404&response_type=code&redirect_uri=${StaticHost}/qidian/home?appId=202363404&scope=clientapi_base&sid=${window.qidian_sid}&state=123&_wv=3`;
}

export default class UserCenterApiRequest extends BaseAPIRequest {
  constructor() {
    super({
      // domain: window.location.hostname === 'localhost' ? AppConf.api.domain : window.location.origin,
      domain: AppConf.api.domain,
      pathPrefix: AppConf.api.pathPrefix,
      fetchOpts: {
        credentials: 'include',
      },
      headers: {
        appToken: getParameterByName('appToken'),
        ...process.env.NODE_ENV === 'development' && {
          appToken: 'f6620ff6729345c8b6101174e695d0ab'
        },
        platform: getParameterByName('platform') || appTokenToPlatform(getParameterByName('appToken')),
        // platform: 'IK'
      },
      interceptor: {
        // 拦截request，设置userToken
        request(opts) {
          const headers = { userToken: getUserToken() };
          const ctx = getCrmContext();
          const isDLJXC = Boolean(getParameterByName('isDLJXC'))
          if (ctx) {
            headers.crmPlatformType = ctx.crmPlatformType;
          }
          if (isDLJXC) {
            headers.isDLJXC = true
          } else {
            delete headers.isDLJXC
          }
          opts.headers = Object.assign({}, opts.headers, headers);
          if (!opts.headers.platform) {
            delete opts.headers.platform;
          }
          if (opts.params) {
            opts.params = removeInputSpaces(opts.params, ['phone']);
          }

          if (opts.body) {
            opts.body = removeInputSpaces(opts.body, ['phone']);
          }
          return opts;
        },

        // 修改返回的数据格式
        response(data) {
          const { code, message, ...others } = data;
          /**
           * @param errorCode
           * 200006 -- userToken过期
           * 200033 -- 当前用户不可用
           * 200086 -- 嵌入其他产品中（比如crm）， 代码传的非产品token，而是uc的token，导致错误
           * 200049 -- TGT已过期
           */
          const { search, pathname, origin } = window.location;
          window.TGTCODE = code;
          const isQD = isQIDIAN();
          if (code === 200006 || code === 200033) {
            if(isQD) {
              jumpToQiDianPage();
            } else {
              const logoutHash = '#/logout';
              window.setTimeout(() => { window.location = origin + pathname + search + logoutHash; }, 1200);
            }
          } else if (code === 200086) {
            window.uc_user_error = 'NOT_PRODUCTION_TOKEN';
          } else if (code === 200049) {
            const { hash } = window.location;
            if (hash !== '#/lxyunLogin' && hash !== '#/login' && hash !== '#/lxyunResetPw' && hash !== "#/signUp") {
              setTimeout(() => {
                if (window.wps || window.wps_id) {
                  // wps跳到默认页
                  window.top.location.href = `${StaticHost}/web/wps.html?appid=${getWpsAppid()}&appToken=${AppConf?.appToken?.IK}#/`
                  return
                } else if (window.platformName == 'DING' || window.crm_app_type === 'dingtalk') {
                  window.location.href = AppConf.CRMHandoverHost.dinglogin
                } else {
                  if(isQD) {
                     jumpToQiDianPage();
                  } else {
                    window.location.href = StaticHost + '/web/index.html' + search + '#/login';
                  }
                }
              }, 100)
            }
          }
          return {
            code,
            message,
            success: code === 0,
            ...others,
          };
        },
        // 统一的错误信息提示，如果需要定制就在对应的api覆盖掉即可
        responseAfter: handleRequestError(),
      },
    });
  }
}
